import React from 'react';
import './LoadingOverlay.css';

const LoadingOverlay = ({ message = 'Loading...' }) => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <div className="loading-wave"></div>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;
