import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Set the worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFViewer({ file, scale, setScale }) {
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);

  console.log('PDFViewer props:', { file, scale });

  if (!file) {
    return <div>No PDF file specified</div>;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error) {
    console.error('Error loading PDF:', error);
    setError(error);
  }

  return (
    <div className="pdf-viewer">
      {error ? (
        <div>Error loading PDF: {error.message}</div>
      ) : (
        <>
          <div className="pdf-controls">
            <button onClick={() => setScale(prevScale => Math.max(0.5, prevScale - 0.1))}>Zoom Out</button>
            <button onClick={() => setScale(prevScale => Math.min(2, prevScale + 0.1))}>Zoom In</button>
          </div>
          <div className="pdf-container">
            <Document
              file={typeof file === 'string' ? file : URL.createObjectURL(file)}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              options={{ workerSrc: "/pdf.worker.js" }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={scale}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(PDFViewer);