import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ fieldName, label, value, options, onSelect, onFocus, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    onSelect(fieldName, e.target.value);
  };

  const handleOptionClick = (option) => {
    onSelect(fieldName, option.name);
    setIsOpen(false);
  };

  return (
    <div className="form-group" ref={dropdownRef}>
      <label htmlFor={fieldName}>{label}:</label>
      <div className="custom-select">
        <input
          type="text"
          id={fieldName}
          name={fieldName}
          value={value || ''}
          onChange={handleInputChange}
          onFocus={() => {
            onFocus(fieldName);
            setIsOpen(true);
          }}
          placeholder={`Select or type ${label}`}
        />
        {value && (
          <span className="extracted-indicator">Extracted from PDF</span>
        )}
        {isOpen && (
          <ul className="options-list">
            {options.length > 0 ? (
              options.map((option) => (
                <li key={option.id} onClick={() => handleOptionClick(option)}>
                  {option.name}
                </li>
              ))
            ) : (
              <li className="no-options">No matching options</li>
            )}
          </ul>
        )}
      </div>
      {isLoading && <span className="loading-indicator">Loading...</span>}
    </div>
  );
};

export default React.memo(Dropdown);
