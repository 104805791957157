import { useState, useCallback } from 'react';
import axios from 'axios';

export function useFileUpload(companyId, fetchCompanyJobs, setToastMessage, docType, shipmentType, shipmentDirection) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState('');

  const onFileChange = useCallback(async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      setStatus('No file selected');
      return;
    }
    setSelectedFile(selectedFile);
    setStatus('Uploading file...');

    if (!companyId) {
      setStatus('Company ID is missing. Please log in again.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('company_id', companyId.toString());
    formData.append('original_filename', selectedFile.name);
    formData.append('doc_type', docType);
    formData.append('shipment_type', shipmentType);
    formData.append('shipment_direction', shipmentDirection);
    console.log(shipmentType);
    console.log(shipmentDirection);
    
    try {
      const response = await axios.post('/upload', formData);
      const message = `File queued. Job ID: ${response.data.job_id}`;
      setStatus(message);
      setToastMessage(message);
      fetchCompanyJobs();
    } catch (error) {
      console.error('Error uploading file:', error);
      const errorMessage = 'Error uploading file: ' + (error.response?.data?.error || error.message);
      setStatus(errorMessage);
      setToastMessage(errorMessage);
    }
  }, [companyId, fetchCompanyJobs, setToastMessage, docType, shipmentType, shipmentDirection]);

  return { selectedFile, status, onFileChange };
}