import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay';
import debounce from 'lodash/debounce';
import Dropdown from './Dropdown';

const OE_ExtractedDataForm = ({ data, onUpdate, onBack }) => {
  const [formData, setFormData] = useState(data?.extractedData || {});
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [partnerOptions, setPartnerOptions] = useState({
    partnerShipperId: [],
    partnerConsigneeId: [],
    partnerNotifyId: [],
    partnerCarrierId: [],
    partnerCustomerId: [],
    partnerBillToId: [],
    partnerActShipperId: []
  });
  const [isLoadingPartners, setIsLoadingPartners] = useState({
    partnerShipperId: false,
    partnerConsigneeId: false,
    partnerNotifyId: false,
    partnerCarrierId: false,
    partnerCustomerId: false,
    partnerBillToId: false,
    partnerActShipperId: false
  });
  const [portOptions, setPortOptions] = useState({
    loadingPortId: [],
    dischargePortId: []
  });
  const [isLoadingPorts, setIsLoadingPorts] = useState({
    loadingPortId: false,
    dischargePortId: false
  });
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const tooltipContent = "The extracted partner names may not correspond to the names of partners that are set up in the system. As such, if there is no close match and if the user doesn't manually select a partner name that is already set up in the system, we will default to a partner called 'Default'.";

  const fetchPartnerOptions = useCallback(async (fieldName, keywords) => {
    setIsLoadingPartners(prev => ({ ...prev, [fieldName]: true }));
    try {
      const response = await axios.get('/partner_options', { params: { keywords } });
      setPartnerOptions(prev => ({ ...prev, [fieldName]: response.data }));
    } catch (error) {
      console.error(`Error fetching partner options for ${fieldName}:`, error);
      setError(`Failed to load options for ${fieldName}`);
    } finally {
      setIsLoadingPartners(prev => ({ ...prev, [fieldName]: false }));
    }
  }, []);

  const debouncedFetchPartnerOptions = useCallback(
    debounce(fetchPartnerOptions, 300),
    []
  );

  const fetchPortOptions = useCallback(async (fieldName, name) => {
    setIsLoadingPorts(prev => ({ ...prev, [fieldName]: true }));
    try {
      const response = await axios.get('/port_options', { params: { name } });
      setPortOptions(prev => ({ ...prev, [fieldName]: response.data }));
    } catch (error) {
      console.error(`Error fetching port options for ${fieldName}:`, error);
      setError(`Failed to load options for ${fieldName}`);
    } finally {
      setIsLoadingPorts(prev => ({ ...prev, [fieldName]: false }));
    }
  }, []);

  const debouncedFetchPortOptions = useCallback(
    debounce(fetchPortOptions, 300),
    []
  );

  useEffect(() => {
    // Initial fetch for each partner field
    ['partnerShipperId', 'partnerConsigneeId', 'partnerNotifyId', 'partnerCarrierId', 'partnerCustomerId', 'partnerBillToId', 'partnerActShipperId'].forEach(fieldName => {
      const initialValue = fieldName === 'partnerCarrierId' 
        ? formData.createShipments?.shipmentBLMainRoutings?.[0]?.[fieldName]
        : fieldName === 'partnerCustomerId' || fieldName === 'partnerBillToId' || fieldName === 'partnerActShipperId'
          ? formData.createShipments?.shipmentHBL?.[0]?.[fieldName]
          : formData.createShipments?.[fieldName];
      if (initialValue) {
        fetchPartnerOptions(fieldName, initialValue);
      }
    });
  }, [fetchPartnerOptions, formData.createShipments]);

  useEffect(() => {
    // Initial fetch for each port field
    ['loadingPortId', 'dischargePortId'].forEach(fieldName => {
      const initialValue = formData.createShipments?.shipmentBLMainRoutings?.[0]?.[fieldName];
      if (initialValue) {
        fetchPortOptions(fieldName, initialValue);
      }
    });
  }, [fetchPortOptions, formData.createShipments]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => {
      const newData = { ...prevData };
      if (name.startsWith('container')) {
        const [index, field] = name.split('-').slice(1);
        newData.createShipments.shipmentBLContainers[index] = {
          ...newData.createShipments.shipmentBLContainers[index],
          [field]: value || null
        };
      } else if (name === 'loadingPortId' || name === 'dischargePortId' || name === 'estTimeArrival' || name === 'estTimeDeparture' || name === 'vesselCodeId' || name === 'voyageNo' || name === 'partnerCarrierId') {
        newData.createShipments.shipmentBLMainRoutings[0] = {
          ...newData.createShipments.shipmentBLMainRoutings[0],
          [name]: value || null
        };
      } else if (name === 'partnerCustomerId' || name === 'partnerBillToId' || name === 'partnerActShipperId') {
        newData.createShipments.shipmentHBL = [{
          ...newData.createShipments.shipmentHBL?.[0],
          [name]: value || null
        }];
      } else {
        newData.createShipments[name] = value || null;
      }
      return newData;
    });

    if (['partnerShipperId', 'partnerConsigneeId', 'partnerNotifyId', 'partnerCarrierId', 'partnerCustomerId', 'partnerBillToId', 'partnerActShipperId'].includes(name)) {
      debouncedFetchPartnerOptions(name, value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError(null);
    
    try {
      // Simulate processing time for 5 seconds
      await new Promise(resolve => setTimeout(resolve, 5000));
      
      const result = await onUpdate({
        ...formData,
        job_id: data.job_id  // Ensure job_id is included
      });

      if (result && result.error) {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error processing form:', error);
      setError(error.message || 'An error occurred while processing the data');
    } finally {
      setIsProcessing(false);
    }
  };

  const addContainer = () => {
    setFormData(prevData => ({
      ...prevData,
      createShipments: {
        ...prevData.createShipments,
        shipmentBLContainers: [
          ...prevData.createShipments.shipmentBLContainers,
          { containerNo: null, sealNo: null, measurementValue: null, weightValue: null }
        ]
      }
    }));
  };

  const handlePartnerSelect = useCallback((fieldName, value) => {
    console.log(`handlePartnerSelect called with fieldName: ${fieldName}, value: ${value}`);
    setFormData(prevData => {
      const newData = { ...prevData };
      if (fieldName === 'partnerCarrierId') {
        newData.createShipments = {
          ...newData.createShipments,
          shipmentBLMainRoutings: [
            {
              ...newData.createShipments.shipmentBLMainRoutings[0],
              [fieldName]: value
            }
          ]
        };
      } else if (fieldName === 'partnerCustomerId' || fieldName === 'partnerBillToId' || fieldName === 'partnerActShipperId') {
        newData.createShipments = {
          ...newData.createShipments,
          shipmentHBL: [
            {
              ...newData.createShipments.shipmentHBL?.[0],
              [fieldName]: value
            }
          ]
        };
      } else {
        newData.createShipments = {
          ...newData.createShipments,
          [fieldName]: value
        };
      }
      return newData;
    });
    debouncedFetchPartnerOptions(fieldName, value);
  }, [debouncedFetchPartnerOptions]);

  const handlePortSelect = useCallback((fieldName, value) => {
    console.log(`handlePortSelect called with fieldName: ${fieldName}, value: ${value}`);
    setFormData(prevData => {
      const newData = {
        ...prevData,
        createShipments: {
          ...prevData.createShipments,
          shipmentBLMainRoutings: [
            {
              ...prevData.createShipments.shipmentBLMainRoutings[0],
              [fieldName]: value
            }
          ]
        }
      };
      return newData;
    });
    debouncedFetchPortOptions(fieldName, value);
  }, [debouncedFetchPortOptions]);

  const handleFocus = useCallback((fieldName) => {
    console.log(`Focus on ${fieldName}`);
  }, []);

  return (
    <div className="extracted-data-container">
      {isProcessing && <LoadingOverlay />}
      <div className="form-header">
        <h2>
          Review Extracted Data
          <span 
            className="tooltip-icon" 
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          >
            ⓘ
            {tooltipVisible && (
              <div className="tooltip-content">
                {tooltipContent}
              </div>
            )}
          </span>
        </h2>
        <div className="form-header-buttons">
          <button type="button" onClick={handleSubmit} className="confirm-process-button">Confirm and Process</button>
          <button type="button" onClick={onBack} className="back-button">Back</button>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="form-content">
        <form onSubmit={handleSubmit} className="extracted-data-form">
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="bookingNo">Booking No:</label>
              <input
                type="text"
                id="bookingNo"
                name="bookingNo"
                value={formData.createShipments?.bookingNo || ''}
                onChange={handleChange}
              />
            </div>
            <Dropdown
              fieldName="partnerShipperId"
              label="Shipper"
              value={formData.createShipments?.partnerShipperId}
              options={partnerOptions.partnerShipperId}
              onSelect={handlePartnerSelect}
              onFocus={handleFocus}
              isLoading={isLoadingPartners.partnerShipperId}
            />
          </div>
          <div className="form-row">
            <Dropdown
              fieldName="partnerConsigneeId"
              label="Consignee"
              value={formData.createShipments?.partnerConsigneeId}
              options={partnerOptions.partnerConsigneeId}
              onSelect={handlePartnerSelect}
              onFocus={handleFocus}
              isLoading={isLoadingPartners.partnerConsigneeId}
            />
            <Dropdown
              fieldName="partnerNotifyId"
              label="Notify Party"
              value={formData.createShipments?.partnerNotifyId}
              options={partnerOptions.partnerNotifyId}
              onSelect={handlePartnerSelect}
              onFocus={handleFocus}
              isLoading={isLoadingPartners.partnerNotifyId}
            />
          </div>
          <div className="form-row">
            <Dropdown
              fieldName="loadingPortId"
              label="Loading Port"
              value={formData.createShipments?.shipmentBLMainRoutings?.[0]?.loadingPortId}
              options={portOptions.loadingPortId}
              onSelect={handlePortSelect}
              onFocus={handleFocus}
              isLoading={isLoadingPorts.loadingPortId}
            />
            <Dropdown
              fieldName="dischargePortId"
              label="Discharge Port"
              value={formData.createShipments?.shipmentBLMainRoutings?.[0]?.dischargePortId}
              options={portOptions.dischargePortId}
              onSelect={handlePortSelect}
              onFocus={handleFocus}
              isLoading={isLoadingPorts.dischargePortId}
            />
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="estTimeArrival">Estimated Time of Arrival:</label>
              <input
                type="datetime-local"
                id="estTimeArrival"
                name="estTimeArrival"
                value={formData.createShipments?.shipmentBLMainRoutings?.[0]?.estTimeArrival?.slice(0, 16) || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="estTimeDeparture">Estimated Time of Departure:</label>
              <input
                type="datetime-local"
                id="estTimeDeparture"
                name="estTimeDeparture"
                value={formData.createShipments?.shipmentBLMainRoutings?.[0]?.estTimeDeparture?.slice(0, 16) || ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="vesselCodeId">Vessel Code:</label>
              <input
                type="text"
                id="vesselCodeId"
                name="vesselCodeId"
                value={formData.createShipments?.shipmentBLMainRoutings?.[0]?.vesselCodeId || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="voyageNo">Voyage No:</label>
              <input
                type="text"
                id="voyageNo"
                name="voyageNo"
                value={formData.createShipments?.shipmentBLMainRoutings?.[0]?.voyageNo || ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <Dropdown
              fieldName="partnerCarrierId"
              label="Carrier"
              value={formData.createShipments?.shipmentBLMainRoutings?.[0]?.partnerCarrierId}
              options={partnerOptions.partnerCarrierId}
              onSelect={handlePartnerSelect}
              onFocus={handleFocus}
              isLoading={isLoadingPartners.partnerCarrierId}
            />
          </div>
          <div className="form-group full-width">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              value={formData.createShipments?.description || ''}
              onChange={handleChange}
            />
          </div>
          <div className="container-section">
            <h3>Containers</h3>
            {formData.createShipments?.shipmentBLContainers?.map((container, index) => (
              <div key={index} className="container-item">
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor={`container-${index}-containerNo`}>Container No:</label>
                    <input
                      type="text"
                      id={`container-${index}-containerNo`}
                      name={`container-${index}-containerNo`}
                      value={container.containerNo || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`container-${index}-sealNo`}>Seal No:</label>
                    <input
                      type="text"
                      id={`container-${index}-sealNo`}
                      name={`container-${index}-sealNo`}
                      value={container.sealNo || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`container-${index}-measurementValue`}>Measurement:</label>
                    <input
                      type="number"
                      id={`container-${index}-measurementValue`}
                      name={`container-${index}-measurementValue`}
                      value={container.measurementValue || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`container-${index}-weightValue`}>Weight:</label>
                    <input
                      type="number"
                      id={`container-${index}-weightValue`}
                      name={`container-${index}-weightValue`}
                      value={container.weightValue || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            ))}
            <button type="button" onClick={addContainer}>Add Container</button>
          </div>

          {/* New HBL section */}
          <div className="hbl-section">
            <h3>HBL Details</h3>
            <div className="form-row">
              <Dropdown
                fieldName="partnerCustomerId"
                label="Customer"
                value={formData.createShipments?.shipmentHBL?.[0]?.partnerCustomerId}
                options={partnerOptions.partnerCustomerId}
                onSelect={handlePartnerSelect}
                onFocus={handleFocus}
                isLoading={isLoadingPartners.partnerCustomerId}
              />
              <Dropdown
                fieldName="partnerBillToId"
                label="Bill To"
                value={formData.createShipments?.shipmentHBL?.[0]?.partnerBillToId}
                options={partnerOptions.partnerBillToId}
                onSelect={handlePartnerSelect}
                onFocus={handleFocus}
                isLoading={isLoadingPartners.partnerBillToId}
              />
            </div>
            <div className="form-row">
              <Dropdown
                fieldName="partnerActShipperId"
                label="Actual Shipper"
                value={formData.createShipments?.shipmentHBL?.[0]?.partnerActShipperId}
                options={partnerOptions.partnerActShipperId}
                onSelect={handlePartnerSelect}
                onFocus={handleFocus}
                isLoading={isLoadingPartners.partnerActShipperId}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(OE_ExtractedDataForm);