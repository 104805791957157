import { useState, useEffect, useCallback } from 'react';

import axios from 'axios';

const docTypeMapping = {
  'notice_of_arrival': 'NOA',
  'booking_confirmation': 'BC',
  // Add more mappings as needed
};

export function useCompanyJobs(token, isLoggedIn) {
  const [companyJobs, setCompanyJobs] = useState([]);
  const [lastJobUpdate, setLastJobUpdate] = useState(Date.now());

  const fetchCompanyJobs = useCallback(async () => {
    if (token) {
      try {
        const response = await axios.get('/company_jobs', {
          headers: { Authorization: token }
        });
        setCompanyJobs(response.data.map(job => ({
          ...job,
          created_at: job.created_at,
          company_timezone: job.company_timezone,
          doc_type: docTypeMapping[job.doc_type] || job.doc_type,
          shipment_type: job.shipment_type ? job.shipment_type.charAt(0).toUpperCase() + job.shipment_type.slice(1).toLowerCase() : '',
          shipment_direction: job.shipment_direction ? job.shipment_direction.charAt(0).toUpperCase() + job.shipment_direction.slice(1).toLowerCase() : ''
        })));
        console.log('Fetched company jobs:', response.data);
        setLastJobUpdate(Date.now()); // Update lastJobUpdate after successful fetch
      } catch (error) {
        console.error('Error fetching company jobs:', error);
      }
    }
  }, [token]);

  useEffect(() => {
    if (isLoggedIn && token) {
      fetchCompanyJobs();
    }
  }, [isLoggedIn, token, fetchCompanyJobs]);

  // Add polling mechanism
  useEffect(() => {
    let intervalId;
    if (isLoggedIn) {
      intervalId = setInterval(() => {
        fetchCompanyJobs();
      }, 10000); // Poll every 10 seconds
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoggedIn, fetchCompanyJobs]);

  const updateJobStatus = useCallback((jobId, newStatus, shipmentNo = null) => {
    setCompanyJobs(prevJobs => 
      prevJobs.map(job => 
        job.job_id === jobId 
          ? { ...job, status: newStatus, shipment_no: shipmentNo || job.shipment_no }
          : job
      )
    );
    setLastJobUpdate(Date.now()); // Update lastJobUpdate when a job status changes
  }, []);

  return { companyJobs, fetchCompanyJobs, updateJobStatus, lastJobUpdate };
}