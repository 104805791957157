import React, { useState } from 'react';
import moment from 'moment-timezone';
import { FaSync, FaCheckCircle, FaExclamationTriangle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function JobQueue({ jobs, onReview, onRetry }) {
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10;

  if (!jobs || jobs.length === 0) {
    return <p>No jobs available.</p>;
  }

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  const totalPages = Math.ceil(jobs.length / jobsPerPage);

  const renderStatus = (status) => {
    switch (status) {
      case 'queued':
        return <span className="queued-status"><FaSync /> Queued</span>;
      case 'processing':
        return <span className="processing-status"><div className="processing-indicator"></div> Processing</span>;
      case 'ready_for_review':
        return <span className="ready-for-review-status"><FaCheckCircle /> Ready for Review</span>;
      case 'submitted':
        return <span className="submitted-status"><FaCheckCircle /> Submitted</span>;
      case 'error':
        return <span className="error-status"><FaExclamationTriangle /> Error</span>;
      
      default:
        return status;
    }
  };

  const formatTime = (timestamp, timezone) => {
    if (!timestamp || !timezone) {
      return 'Unknown';
    }
    return moment.tz(timestamp, timezone).fromNow();
  };

  console.log('Jobs received in JobQueue:', jobs); // Add this line for debugging

  return (
    <div className="job-queue">
      <div className="job-queue-header">
        <h2>Job Queue</h2>
        <div className="pagination">
          <button 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span>
            Page {currentPage} of {totalPages} | 
            Showing {indexOfFirstJob + 1}-{Math.min(indexOfLastJob, jobs.length)} of {jobs.length} records
          </span>
          <button 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
      <table className="job-queue-table">
        <thead>
          <tr>
            <th className="filename-column">Filename</th>
            <th className="doc-type-column">Type</th>
            <th className="shipment-type-column">Shipment Type</th>
            <th className="shipment-direction-column">Direction</th>
            <th className="status-column">Status</th>
            <th className="last-updated-column">Last Updated</th>
            <th className="action-column">Action</th>
            <th className="view-column">View</th>
          </tr>
        </thead>
        <tbody>
          {currentJobs.map((job) => (
            <tr key={job.job_id}>
              <td className="filename-column" title={job.filename}>
                {job.filename}
              </td>
              <td className="doc-type-column">{job.doc_type || 'N/A'}</td>
              <td className="shipment-type-column">{job.shipment_type || 'N/A'}</td>
              <td className="shipment-direction-column">{job.shipment_direction || 'N/A'}</td>
              <td className="status-column">{renderStatus(job.status)}</td>
              <td className="last-updated-column">
                {job.created_at && job.company_timezone
                  ? formatTime(job.created_at, job.company_timezone)
                  : 'Unknown'}
              </td>
              <td className="action-column">
                {job.status === 'ready_for_review' && (
                  <button onClick={() => onReview(job.job_id)}>Review</button>
                )}
                {job.status === 'error' && (
                  <button onClick={() => onRetry(job.job_id)} className="retry-button">
                    <FaSync /> Retry
                  </button>
                )}
              </td>
              <td className="view-column">
                {job.shipment_url && (
                  <a href={job.shipment_url} target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(JobQueue);
