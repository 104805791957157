import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

export function useJobReview(updateJobStatus) {
  const [reviewData, setReviewData] = useState(null);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Add this useEffect to set the token when the component mounts
  useEffect(() => {
    const token = localStorage.getItem('token');
    const fr8labsAuthToken = localStorage.getItem('fr8labs_auth_token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
    if (fr8labsAuthToken) {
      axios.defaults.headers.common['Fr8labs-Authorization'] = fr8labsAuthToken;
    } else {
      delete axios.defaults.headers.common['Fr8labs-Authorization'];
    }
  }, []);

  const handleReview = useCallback(async (jobId) => {
    setIsLoading(true);
    setStatus('Fetching job data...');
    try {
      console.log(`Fetching review for job ${jobId}`);
      const [reviewResponse, pdfResponse] = await Promise.all([
        axios.get(`/review/${jobId}`),
        axios.get(`/file_path/${jobId}`)
      ]);
      
      console.log('Review response:', reviewResponse.data);
      const reviewDataWithTypes = { 
        ...reviewResponse.data, 
        job_id: jobId,
        shipment_type: reviewResponse.data.shipment_type,
        shipment_direction: reviewResponse.data.shipment_direction
      };
      setReviewData(reviewDataWithTypes);
      
      // Use the full URL for the PDF
      const pdfUrl = `${axios.defaults.baseURL}/pdf/${jobId}?t=${Date.now()}`;
      console.log('Setting PDF URL:', pdfUrl);
      setCurrentPdfUrl(pdfUrl);
      
      if (reviewResponse.data.file_path !== pdfResponse.data.file_path) {
        console.warn('Mismatch between review data and PDF file path');
        setStatus('Warning: Possible mismatch between review data and PDF');
      } else {
        setStatus('');
      }
      setIsLoading(false);
      return reviewDataWithTypes;
    } catch (error) {
      console.error('Error fetching job for review:', error);
      setStatus('Error fetching job for review: ' + error.message);
      setIsLoading(false);
      throw error;
    }
  }, []);

  const onReviewComplete = useCallback(async (updatedData) => {
    setStatus('Processing and submitting shipment...');
    try {
      console.log('Updated data in onReviewComplete:', updatedData);
      const processResponse = await axios.post('/process', updatedData);
      const submitResponse = await axios.post('/submit', processResponse.data);
      const shipmentNo = submitResponse.data.data.createShipment.shipmentNo;
      const shipmentId = submitResponse.data.data.createShipment.shipmentId;
      const billofLadingNo = submitResponse.data.data.createShipment.billofLadingNo;
      
      console.log('The updatedData shipment_type is: ', updatedData.shipment_type);
      console.log('The updatedData shipment_direction is: ', updatedData.shipment_direction);

      // Determine the shipment URL based on shipment_type and shipment_direction
      let shipmentUrl;
      if (updatedData.shipment_type === 'ocean' && updatedData.shipment_direction === 'import') {
        shipmentUrl = `https://user.fr8labs.co/en/shipment/ocean-import/${shipmentNo}`;
      } else {
        shipmentUrl = `https://user.fr8labs.co/en/shipment/ocean-export/${shipmentNo}`;
      }

      setReviewData({ ...updatedData, shipmentUrl, shipmentNo, shipmentId, billofLadingNo });
      setStatus('Shipment submitted successfully...');
      
      if (updatedData.job_id) {
        await axios.post(`/update_job/${updatedData.job_id}`, {
          status: 'submitted',
          shipmentNo: shipmentNo,
          shipmentUrl: shipmentUrl,
          shipmentId: shipmentId,
          billofLadingNo: billofLadingNo
        });
        updateJobStatus(updatedData.job_id, 'submitted', shipmentNo, shipmentId, billofLadingNo);
      } else {
        console.error('Job ID is undefined. Unable to update job status.');
      }
    } catch (error) {
      setStatus('Error processing and submitting shipment: ' + error.message);
    }
  }, [updateJobStatus]);

  return { reviewData, currentPdfUrl, status, isLoading, handleReview, onReviewComplete };
}