import { useState, useEffect } from 'react';
import axios from 'axios';

export const useAuth = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [companyId, setCompanyId] = useState(localStorage.getItem('companyId'));

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedCompanyId = localStorage.getItem('companyId');
    if (storedToken && storedCompanyId) {
      setToken(storedToken);
      setCompanyId(storedCompanyId);
      setIsLoggedIn(true);
    }
  }, []);

  const handleInviteCodeSubmit = async (inviteCode) => {
    try {
      const response = await axios.post('/company_login', { invite_code: inviteCode });
      if (response.data.token) {
        setToken(response.data.token);
        setIsLoggedIn(true);
        setCompanyId(response.data.company_id);
        localStorage.setItem('companyName', response.data.company_name);
        localStorage.setItem('companyId', response.data.company_id);
        localStorage.setItem('fr8labs_auth_token', response.data.fr8labs_auth_token);
        localStorage.setItem('token', response.data.token);
        return true;
      }
    } catch (error) {
      console.error('Error submitting invite code:', error);
    }
    return false;
  };

  return {
    token,
    isLoggedIn,
    companyId,
    handleInviteCodeSubmit
  };
};